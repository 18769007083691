import React from "react";
import styles from './ForestInsights.module.css';

// import Newsimg1 from "../assects/Newsimg1.webp"
import Newsimg2 from '../assects/Topnewsimg.webp'

import AshwagandhaNewsimg from '../assects/ash-bottle.png'
import HoneyCoriandor from '../assects/Coriander.png'
import Herbletea from '../assects/HERBAL-TEA.png'
import Jaggery from '../assects/Jaggery.png'
import Moringa from '../assects/moronga.jpeg'

import HoneyAnn from '../assects/Coriander.png'
import Teahambox from '../assects/TeaHamper.webp'
import Articleimg from '../assects/Articleimg.webp'
import newsimg1 from '../assects/articleimg1.webp'

import Globlepost from '../assects/Newspost.jpg'
import Globlepost1 from '../assects/globlepost1.png'
import Ashfacts from '../assects/Ashwagandhafacts.png'

import seasonaltip from '../assects/wintertip.webp'
import honeysoon from '../assects/honeycomsoon.webp'
import honeyham from '../assects/HoneyHamper.jpeg'
import Shilajit from '../assects/Shilajit.jpeg'



const Insights = () => {

    return (
        <div>
            <div className={styles.Insights}>
                <div className={styles.Insightshead}><h1>THE GREEN REPORT</h1></div>
                <div className={styles.topimage}><img src={Newsimg2} alt="This is the forest image "></img>
                    {/* <div><a>THE GREEN REPORT</a></div> */}
                </div>

                <div className={styles.leftcontent}><h2>FOREST INSIGHTS</h2>
                        <br />
                        <ul className={styles.sidebarcss}>
                            <li className={styles.sidebaritem}><a href='#ANNOUNCEMENT'>ANNOUNCEMENT</a></li>
                            <li className={styles.sidebaritem}><a href="#globlebox">GLOBLE UPDATES</a></li>
                            <li className={styles.sidebaritem}><a href="#article">ARTICLES</a></li>
                            <li className={styles.sidebaritem}><a href="#Posts">POSTS</a></li>
                            <li className={styles.sidebaritem}><a href="#upcoming event and workshop">UPCOMING EVENT</a></li>
                            <li className={styles.sidebaritem}><a href="#seasonal health tips">SEASONAL HEALTH TIPS</a></li>
                            <li className={styles.sidebaritem}><a href="#reviews">CUSTOMER REVIEWS</a></li>

                        </ul>
                    </div>

                <div className={styles.Insightscontent}>
                    <div className={styles.centercontent}>
                        <div>
                            <h2>STAY CONNECTED WITH FOREST WELLNESS </h2>
                            <br />
                            <div id="ANNOUNCEMENT" className={styles.Announcementbox}>
                                <h2>ANNOUNCEMENT</h2>
                                <div>
                                    <img src={HoneyAnn} alt="This is an image of our resent product Honey"></img>
                                </div>
                                <p>We’re thrilled to announce that Forest Wellness is about to introduce two incredible new products:<br />
                                    <br />
                                    Forest Honey and Ashwagandha! 🌿✨<br />
                                    Get ready to experience the pure essence of nature with these wellness treasures, crafted to nurture your health and vitality.<br />
                                    The launch is just a few days away—stay tuned for more updates!
                                    Your journey to wellness begins here.</p>
                            </div>

                            <div id='#seasonal health tips'className={styles.Healthprobox}>
                                <div className={styles.Healthbox}>
                                    <h2>SEASONAL HEALTH TIPS</h2>
                                    <div>
                                        <img src={seasonaltip} alt="This is an image of our resent product Honey"></img>
                                    </div>
                                    <p>"Stay Warm and Boost Immunity with Herbal Tea and Honey!" </p>
                                </div>

                                <div className={styles.Productbox}>
                                    <h2>UPCOMING PRODUCT UPDATES</h2>
                                    <div>
                                        <img src={honeysoon} alt="This is an image of our resent product Honey"></img>
                                    </div>
                                    <p>🍯 Coming Soon: Forest Wellness Organic Honey! 🌿</p>
                                </div>
                            </div>

                            <div id='#globlebox'className={styles.Globlebox}>
                                <h2>GLOBAL UPDATES</h2>
                                <h3>THE RISE OF SYNTHETIC FOOD</h3>
                                <div>
                                    <img src={Globlepost1} alt="This is an image of our resent product Honey"></img>
                                </div>

                                <p>The April 1, 2024 session of the IPAK-EDU Director's Science Webinar featured the research of Alexis Baden-Mayer. The research she presented weaves a timeline of people and organizations, from the end of the 19th century to the present, and raises serious questions about the future of food and the intentions of the actors and organizations involved.</p>
                            </div>

                            <div className={styles.Healthprobox}>
                                <div id='reviews' className={styles.Healthbox}>
                                    <h2>CUSTOMER REVIEWS</h2>
                                    <div>
                                        <img src={HoneyAnn} alt="This is an image of our resent product Honey"></img>
                                    </div>
                                    <p>Get ready to experience the pure essence of nature </p>
                                </div>

                                <div id='upcoming event and workshop'className={styles.Productbox}>
                                    <h2>UPCOMING EVENT AND WORKSHOP</h2>
                                    <div>
                                        <img src={Teahambox} alt="This is an image of our resent product Honey"></img>
                                    </div>
                                    <p>Get ready to experience the pure essence of nature </p>
                                </div>
                            </div>

                            <div id='article'className={styles.Articlebox}>
                                <h2>ARTICLES</h2>
                                <div>
                                    <img src={newsimg1} alt="This is an image of our resent product Honey"></img>
                                </div>
                                <div className={styles.articletext}>
                                    <h3>🌱Embracing Organic Wellness: A Path to Holistic Living 🌿</h3>
                                    <div className={styles.articleptext}><p>In a world seeking balance, organic wellness products are becoming a cornerstone of healthier living.
                                        🌍These products, crafted from naturally sourced ingredients free from harmful chemicals, prioritize purity,
                                        sustainability, and overall well-being.</p></div>

                                    <h4>Why Go Organic?</h4>
                                    <ul> <li>Healthier Choices: Packed with nutrients and free from toxins.🍎</li>
                                        <li>Eco-Friendly: Supports sustainable farming and biodiversity.🌾</li>
                                        <li>Ethical: Promotes cruelty-free, planet-friendly practices.🌏</li></ul>
                                    <div> <p className={styles.articleptext}>From raw honey and herbal supplements like ashwagandha and moringa to
                                        soothing herbal teas and nutrient-rich jaggery powder, organic wellness
                                        products offer a natural way to boost immunity, reduce stress, and embrace mindful living.<br />
                                        Choosing organic isn’t just a trend — it’s a lifestyle that nurtures both you and the planet. 🌿
                                    </p></div>
                                </div>
                            </div>

                            <div id="Posts" className={styles.postbox}>
                                <h2>POSTS</h2>
                                <div>
                                    <img src={Ashfacts} alt="This is an image of our resent product Honey"></img>
                                </div>
                                <div>
                                    <h3>BENIFITS OF ASHWAGANDHA</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={styles.rightcontent}><h2>OUR PRODUCTS</h2>
                        <div className={styles.newsproduct}>
                            <img src={AshwagandhaNewsimg} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={HoneyCoriandor} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={Herbletea} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={Jaggery} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={Moringa} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={Teahambox} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={honeyham} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>

                        <div className={styles.newsproduct}>
                            <img src={Shilajit} alt="Ashwagandha image"></img>
                            <button className={styles.rightprobuybtn}><a>BUY NOW</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Insights;