import React from "react";
import StarRating from "./startRating";
import './RetailProducts.css';
import ashbottle from '../assects/ash-bottle.png';
import allproimg from '../assects/6.jpg';
// import Whatweoffer from '../assects/whatweoffer.jpg';

import Jaggery from '../assects/Jaggery.png';
import Multiflora from '../assects/Multiflora.png';
import Honeyorg from '../assects/Honeyorg.png'
import Babool from '../assects/Babool.png';
import HerbalTea from '../assects/HERBAL-TEA.png';
import Mustard from '../assects/Mustard.png';
import Coriander from '../assects/Coriander.png';
import Pink from '../assects/PinkTea.jpeg';
import Trible from '../assects/Trible Tea.jpeg';
import Moring from '../assects/moronga.jpeg';
import GreenTea from '../assects/Green Tea.jpeg';
import Shilajit from '../assects/Shilajit.jpeg';

import HamperTea from '../assects/TeaHamper.webp';
import HamperHoney from '../assects/HoneyHamper.jpeg'
// import Slider from "react-slick"; 



const RetailProducts = () => {

    return (
        <div>
            <div className="pro-slider">
                <div className="product-img-slider">
                    <img src={allproimg} alt="" />
                    {/* <img src={Whatweoffer} alt="" /> */}
                    <img src="" alt="" />
                    <img src="" alt="" />
                </div>
            </div>

            <div className="all-products">
                <div className="all-pro">
                    <h2 className="sub-title">Wellness Products</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={ashbottle} alt="ashwagandha" />
                            <button><a>Ashwagandha</a></button>
                        </div>

                        {/* <div className="sub-box">
                            <img src={Jaggery} alt="" />
                            <button><a>Jaggery</a></button>
                        </div> */}

                        <div className="sub-box">
                            <img src={Honeyorg} alt="Honey" />
                            <button><a>Honey</a></button>
                        </div>


                        <div className="sub-box">
                            <img src={Moring} alt="Moringa Powder" />
                            <button><a>Moringa </a></button>
                        </div>

                        {/* <div className="sub-box">
                            <img src={Shilajit} alt="Moringa Powder" />
                            <button><a>Shilajit</a></button>
                        </div> */}
                    </div>
                </div>

                {/* <div className="all-pro">
                    <h2 className="sub-title">Honey</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={Babool} alt="honey" />
                            <button><a>Honey (Babool)</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={Multiflora} alt="" />
                            <button><a>Honey (Multiflora)</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={Mustard} alt="" />
                            <button><a>Honey (Mustard)</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={Coriander} alt="" />
                            <button><a>Honey (Coriander)</a></button>
                        </div>
                    </div>
                </div> */}

                <div className="all-pro">
                    <h2 className="sub-title"> UPCOMING PRODUCTS</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={HerbalTea} alt="Hearbal Tea" />
                            <button><a>Bodhipia Tea</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={Jaggery} alt="Hearbal Tea" />
                            <button><a>Jaggery Powder</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={Pink} alt="Hearbal Tea" />
                            <button><a>Pink Tea</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={GreenTea} alt="Hearbal Tea" />
                            <button><a>Green Tea</a></button>
                        </div>
                    </div>
                </div>

                {/* <div className="all-pro">
                    <h2 className="sub-title">Gifting</h2>
                    <div className="boxes">
                        <div className="sub-box">
                            <img src={HamperHoney} alt="Hearbal Tea" />
                            <button><a>Honey</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={HamperTea} alt="Hearbal Tea" />
                            <button><a>Herbal Tea</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={Pink} alt="Hearbal Tea" />
                            <button><a>wellness</a></button>
                        </div>

                        <div className="sub-box">
                            <img src={GreenTea} alt="Hearbal Tea" />
                            <button><a>Green Tea</a></button>
                        </div>
                    </div>
                </div> */}

            </div>

            <div className="reviews">
                <div>
                    <h1>Reviews</h1>
                    <div className="review-box">
                        <div className="rev">
                            <h2>Atharv04</h2>
                            <StarRating count={4} size="1x" color="#ffc107" />
                            <p>"I've been using Forest Wellness Ashwagandha for a month now, and the results are fantastic. I feel more relaxed and my stress levels have significantly decreased. The quality is outstanding, and I love that it's sourced from natural, sustainable forests."</p>
                        </div>

                        <div className="rev">
                            <h2>Tanish00</h2>
                            <StarRating count={3} size="1x" color="#ffc107" />
                            <p>"I recently switched to Forest Wellness Stevia, and it’s been a game-changer. It’s a natural, zero-calorie sweetener that doesn’t compromise on taste. Perfect for my coffee and desserts, and it feels good to use a product that’s sustainably harvested from nature."</p>
                        </div>

                        <div className="rev">
                            <h2>Ravi590</h2>
                            <StarRating count={5} size="1x" color="#ffc107" />
                            <p>"Forest Wellness Honey is simply the best. It’s pure, organic, and has a rich, delicious flavor. I use it every morning in my tea, and it’s also great for baking. Knowing it comes from pristine forest areas makes it even better."</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RetailProducts;

