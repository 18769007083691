import './App.css';
import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components /Navbar';
import Home from './components /Home';
import RetailProducts from './components /RetailProducts';
import BukProducts from './components /BulkProducts';
import ForestInsights from './components /ForestInsights';
import Contact from './components /Contact';



function App() {

  const sectionRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          }
        });
      },
      { threshold: 0.1 }
    );

    sectionRefs.current.forEach(section => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionRefs.current.forEach(section => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);


  return (
    <Router>
      <div className="App">
        <Navbar />
        <div ref={el => sectionRefs.current[0] = el} className="section">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Retail-products" element={<RetailProducts />} />
            <Route path="/BulkProducts" element={<BukProducts />} />
            <Route path="/ForestInsights" element={<ForestInsights />}  />
          </Routes>
        </div>

        <div ref={el => sectionRefs.current[1] = el} className="section">
          <Contact />
        </div>

      </div>
    </Router>
  );
}

export default App;


